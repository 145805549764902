















































































































































































































































































































































































































































































































































import BN from 'bignumber.js';
import WeaponGrid from '../components/smart/WeaponGrid.vue';
import BigButton from '../components/BigButton.vue';
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import WeaponIcon from '../components/WeaponIcon.vue';
import { BModal } from 'bootstrap-vue';
import { Contracts, IState } from '@/interfaces';
import { Accessors } from 'vue/types/options';
import { getCleanName, isProfaneIsh } from '../rename-censor';
// import VueRouter from 'vue-router';

type StoreMappedState = Pick<IState, 'defaultAccount'| 'ownedWeaponIds'>;

interface StoreMappedGetters {
  contracts: Contracts;
  ownWeapons: any[];
  nftsCount: number;
}

interface Data {
  showBlacksmith: boolean,
  showDustForge: boolean,
  showReforgeDust: boolean,
  reforgeWeaponId: string | null;
  burnWeaponId: string | null;
  selectedNft: string | null;
  forgeCost: string;
  reforgeCost: string;
  dustReforgeCost: string,
  burnCost: string,
  disableForge: boolean;
  newForged: number[];
  currentListofWeapons: string[];
  spin: boolean;
  lesserDust: string,
  greaterDust: string,
  powerfulDust: string,
  dust: string[],
  allowDustForge: false,
  burnWeaponIds: any[],
  weaponRename: string;
  haveRename: string;
  onError: boolean;
  hideWeapons: any[];
}

export default Vue.extend({
  data() {
    return {
      showBlacksmith: true,
      showDustForge: false,
      showReforgeDust: false,
      reforgeWeaponId: null,
      burnWeaponId: null,
      selectedNft: null,
      forgeCost: '0',
      reforgeCost: '0',
      dustReforgeCost: '0',
      burnCost: '0',
      disableForge: false,
      newForged: [],
      currentListofWeapons: [],
      spin: false,
      lesserDust: '0',
      greaterDust: '0',
      powerfulDust: '0',
      dust: [],
      allowDustForge: false,
      burnWeaponIds: [],
      weaponRename: '',
      haveRename: '0',
      onError: false,
      hideWeapons: [],
    } as Data;
  },

  computed: {
    ...(mapState(['defaultAccount','ownedWeaponIds','ownedShieldIds']) as Accessors<StoreMappedState>),
    ...(mapGetters([
      'contracts', 'ownWeapons', 'nftsCount', 'ownShields',
      'getPowerfulDust', 'getGreaterDust', 'getLesserDust'
    ]) as Accessors<StoreMappedGetters>),

    isRenameProfanish(): boolean {
      return isProfaneIsh(this.weaponRename);
    },

    cleanRename(): string {
      return getCleanName(this.weaponRename);
    }
  },

  watch: {
    reforgeWeaponId() {
      this.burnWeaponId = null;
    }
  },

  async created() {
    if(!this.contracts.CryptoWars) return;
    // const forgeCost = await this.contracts.CryptoWars.methods.mintWeaponFee().call({ from: this.defaultAccount });
    const skillForgeCost = 0; /* await this.contracts.CryptoWars.methods.usdToxBlade(forgeCost).call({ from: this.defaultAccount });*/
    this.forgeCost = new BN(skillForgeCost).div(new BN(10).pow(18)).toFixed(4);

    const reforgeCost = await this.contracts.CryptoWars.methods.reforgeWeaponFee().call({ from: this.defaultAccount });
    const skillReforgeCost =  await this.contracts.CryptoWars.methods.usdToxBlade(reforgeCost).call({ from: this.defaultAccount });
    this.reforgeCost = new BN(skillReforgeCost).div(new BN(10).pow(18)).toFixed(4);

    const reforgeDustCost = await this.contracts.CryptoWars.methods.reforgeWeaponWithDustFee().call({ from: this.defaultAccount });
    const skillDustReforgeCost =  await this.contracts.CryptoWars.methods.usdToxBlade(reforgeDustCost).call({ from: this.defaultAccount });
    this.dustReforgeCost = new BN(skillDustReforgeCost).div(new BN(10).pow(18)).toFixed(4);

    const burnCost = await this.contracts.CryptoWars.methods.burnWeaponFee().call({ from: this.defaultAccount });
    const skillBurnCost =  await this.contracts.CryptoWars.methods.usdToxBlade(burnCost).call({ from: this.defaultAccount });
    this.burnCost = new BN(skillBurnCost).div(new BN(10).pow(18)).toFixed(4);
    this.hideWeapons = this.ownedWeaponIds;
    // if(!this.contracts.WeaponRenameTagConsumables) return;
    // this.haveRename = await this.contracts.WeaponRenameTagConsumables.methods.getItemCount().call({ from: this.defaultAccount });
  },

  methods: {
    ...mapActions(['reforgeWeapon', 'renameWeapon',
      'fetchTotalWeaponRenameTags', 'burnWeapon', 'reforgeWeaponWithDust', 'massBurnWeapons']),

    relayFunction(offset: number){
      try{
        this.viewNewWeapons(offset);
      } catch (e) {
        console.error(e);
        this.onError = true;
      }
    },

    onShowForgeDetails() {
      (this.$refs['forge-details-modal'] as BModal).show();
    },

    showDustReforgeConfirmation() {
      if (this.reforgeWeaponId !== null && (this.lesserDust !== '0' || this.greaterDust !== '0'|| this.powerfulDust !== '0')) {
        (this.$refs['dustreforge-confirmation-modal'] as BModal).show();
      }
    },

    showMassDustConfirmation() {
      (this.$refs['mass-dust-confirmation-modal'] as BModal).show();
    },

    displayDustReforge() {
      this.showBlacksmith = false;
      this.showReforgeDust = true;
      this.showDustForge = false;
      this.lesserDust = '0';
      this.greaterDust = '0';
      this.powerfulDust = '0';
    },
    displayDustCreation(){
      return this.showBlacksmith = false,
      this.showDustForge = true,
      this.showReforgeDust = false,
      this.hideWeapons = this.ownedWeaponIds;
    },
    displayBlacksmith(){
      this.showBlacksmith = true;
      this.showDustForge = false;
      this.showReforgeDust = false;
    },
    cancelReforge() {
      this.showBlacksmith = true;
      this.showDustForge = false;
      this.showReforgeDust = false;
      this.burnWeaponIds = [];
      this.hideWeapons = this.ownedWeaponIds;
      this.lesserDust = '0';
      this.greaterDust = '0';
      this.powerfulDust = '0';
      this.reforgeWeaponId = null;
    },
    clearAllMassBurn(){
      return this.burnWeaponIds = [],
      this.hideWeapons = this.ownedWeaponIds;
    },
    getWeaponToUpgrade() {
      return this.ownWeapons.find(x => x.id === this.reforgeWeaponId);
    },

    addBurnWeapon(id: number){
      this.burnWeaponIds.push(id.toString());
      this.hideWeapons = this.hideWeapons.filter(val => !this.burnWeaponIds.includes(val));
      this.burnWeaponId = null;
    },

    removeBurnWeapon(id: number){
      this.hideWeapons.push(id.toString());
      this.burnWeaponIds = this.burnWeaponIds.filter(x => x !== id.toString());
    },

    viewNewWeapons(offset: number){
      this.newForged = [];
      this.ownedWeaponIds.forEach(x => {
        this.newForged.push(x);
      });

      this.newForged.splice(0, this.ownedWeaponIds.length - offset + 1);


      // eslint-disable-next-line no-constant-condition
      if (this.newForged.length > 0 && !this.onError){
        this.spin = true;
        (this.$refs['new-weapons'] as BModal).show();

        setTimeout(() => {
          this.spin = false;
        }, 10000);
      }

    },

    closeNewWeaponsModal() {
      (this.$refs['new-weapons'] as BModal).hide();
    },

    async onReforgeWeaponWithDust() {
      try {
        (this.$refs['new-weapons'] as BModal).show();
        this.spin = true;
        await this.reforgeWeaponWithDust({
          reforgeWeaponId: this.reforgeWeaponId,
          lesserDust: this.lesserDust,
          greaterDust: this.greaterDust,
          powerfulDust:this.powerfulDust
        });

        this.lesserDust = '0';
        this.greaterDust = '0';
        this.powerfulDust = '0';
        this.spin = false;

      } catch (e) {
        console.error(e);
        (this as any).$dialog.notify.error('Could not ReForge sword: insufficient funds / Dust or transaction denied.');
        (this.$refs['new-weapons'] as BModal).hide();
        this.spin = false;
      }
    },

    async onMassBurnWeapons() {
      try {
        (this.$refs['new-dust'] as BModal).show();
        this.spin = true;
        await this.massBurnWeapons({
          burnWeaponIds: this.burnWeaponIds,
        });
        this.burnWeaponIds = [];
        this.burnWeaponId = null;
        this.spin = false;
      } catch (e) {
        console.error(e);
        (this.$refs['new-dust'] as BModal).hide();
        this.spin = false;
        (this as any).$dialog.notify.error('Could not burn sword: insufficient funds or transaction denied.');
      }
    },
    canRename() {
      return this.reforgeWeaponId !== null && +this.haveRename > 0;
    },
    openRenameWeapon() {
      (this.$refs['weapon-rename-modal'] as BModal).show();
    },
    async renameWeaponCall() {
      if(this.weaponRename === '' || this.reforgeWeaponId === null){
        return;
      }

      await this.renameWeapon({id: this.reforgeWeaponId, name: this.weaponRename});
      if(this.contracts.WeaponRenameTagConsumables) {
        this.haveRename = await this.contracts.WeaponRenameTagConsumables.methods.getItemCount().call({ from: this.defaultAccount });
      }
    }
  },

  components: {
    WeaponGrid,
    BigButton,
    WeaponIcon,
    BModal,
  },
});
