








































































































































import { mapGetters, mapState, mapMutations } from "vuex";
import { getCharacterArt } from "../../character-arts-placeholder";
// import SmallBar from "../SmallBar.vue";
// import CharacterArt from "../CharacterArt.vue";
import { CharacterPower, CharacterTrait } from "../../interfaces";
import { RequiredXp } from "../../interfaces";
// import Hint from "../Hint.vue";
import Vue from "vue";
import { toBN, fromWeiEther } from "../../utils/common";
import { getCleanName } from "../../rename-censor";

export default Vue.extend({
  components: {
    // CharacterArt,
    // SmallBar,
    // Hint,
  },

  computed: {
    ...mapState(["maxStamina", "currentCharacterId", "ownedCharacterIds", "secondsPerStamina"]),
    ...mapGetters([
      "currentCharacter",
      "currentCharacterStamina",
      "getCharacterName",
      "getCharacterStamina",
      "charactersWithIds",
      "ownCharacters",
      "timeUntilCharacterHasMaxStamina",
      "getSecondPerStamina",
      "getIsInCombat",
      "getIsCharacterViewExpanded",
      "fightGasOffset",
      "fightBaseline",
      "minutesPerStamina"
    ]),

    isLoadingCharacter(): boolean {
      return !this.currentCharacter;
    },

    filteredCharactersForList(): any {
      const items: any = this.ownCharacters;
      return items;
    },
  },

  data() {
    return {
      traits: CharacterTrait,
      isPlaza: false,
      staminaTimer: 0
    };
  },
  methods: {
    ...mapMutations(["setCurrentCharacter"]),
    getCharacterArt,
    CharacterPower,
    RequiredXp,

    setListClassForSelChar(id: string, currentCharId: string): any {
      if (id === currentCharId) {
        return "character-highlight";
      } else return "character";
    },

    toolTipHtml(time: string, minutesPerStamina: string): string {
      return (
        `Regenerates 1 point every ${minutesPerStamina} minutes, stamina bar will be full at: ` +
        time
      );
    },

    formattedSkill(skill: number): number {
      const skillBalance = fromWeiEther(skill.toString());
      return toBN(skillBalance).toNumber();
    },

    getCleanCharacterName(id: string): string {
      return getCleanName(this.getCharacterName(id));
    },
  },
});
