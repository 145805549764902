

























import { mapState } from "vuex";

export default {
  props: {},

  computed: {
    ...mapState(["defaultAccount"]),
  },
  methods: {
    copyReferralLink: (defaultAccount: string) => {
      navigator.clipboard.writeText(
        `https://play.cryptowar.network/?r=${defaultAccount}`
      );
      alert("Referral link copied to clipboard");
    },
  },
};
