














































import CharacterRoomArtPvp from "../components/CharacterRoomArtPvp.vue";
import { mapActions, mapState } from "vuex";
import Web3 from "web3";

export default {
  props: ["characterId", "room", "selectedWeaponId", "selectedCharacterId", "isRequest", "handleRequestFight","handleCancelFight", "isCancel", "handleShowWeapon"],
  components: {
    CharacterRoomArtPvp,
  },
  methods: {
    ...mapActions(["fetchCharacters", "requestFight", "getStartTimeRoom"]),
    // handleRequestFight() {
    //   //@ts-ignore
    //   this.requestFight({
    //     roomId: this.room.id,
    //     weaponId: this.selectedWeaponId,
    //     characterId: this.selectedCharacterId,
    //   });
    // },
    checkTimeAvailable() {
      //@ts-ignore
      if(!this.isCancel) {
        return false;
      }
      const now = new Date();
      //@ts-ignore
      const timeStart = new Date(this.timeRoomStart*1000);
      if(now.getFullYear() > timeStart.getFullYear()){
        return true;
      }else if(now.getMonth() > timeStart.getMonth()) {
        return true;
      }else if(now.getDate()> timeStart.getDate()) {
        return true;
      }else if(now.getHours() > timeStart.getHours() +1) {
        return true;
      }
      else {
        return false;
      }
    }
  },
  computed: {
    ...mapState(["characters"]),
    //@ts-ignore
    matchReward() {
      //@ts-ignore
      return Web3.utils.fromWei(this.room.matchReward, "ether");
    },

  },
  data() {
    return{
      timeRoomStart: 0
    };
  },
  async mounted() {
    //@ts-ignore
    await this.fetchCharacters([this.characterId]);
    //@ts-ignore
    this.timeRoomStart = await this.getStartTimeRoom({roomId: this.room.id});
    //@ts-ignore
    console.log('test', this.checkTimeAvailable());
  },
};
